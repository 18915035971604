<template>
  <b-row>
    <b-col cols="12">
      <b-card-code
        title=""
        no-body
      >
        <b-card-body>

          <b-form @submit.prevent="submit">
            <b-row>

              <!-- Name -->
              <b-col md="6">
                <b-form-group
                  label="Name"
                  label-for="mc-name"
                >
                  <b-form-input
                    id="mc-name"
                    v-model="vendor.name"
                    placeholder="Name"
                  />
                </b-form-group>
              </b-col>

              <!-- Legal Name -->
              <b-col md="6">
                <b-form-group
                  label="Legal Name"
                  label-for="mc-legal_name"
                >
                  <b-form-input
                    id="mc-legal_name"
                    v-model="vendor.legal_name"
                    placeholder="Legal Name"
                  />
                </b-form-group>
              </b-col>

              <!-- SSN -->
              <b-col md="6">
                <b-form-group
                  label="SSN"
                  label-for="mc-ssn"
                >
                  <b-form-input
                    id="mc-ssn"
                    v-model="vendor.ssn"
                    placeholder="SSN"
                  />
                </b-form-group>
              </b-col>

              <!-- EIN -->
              <b-col md="6">
                <b-form-group
                  label="EIN"
                  label-for="mc-ein"
                >
                  <b-form-input
                    id="mc-ein"
                    v-model="vendor.ein"
                    placeholder="EIN"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Type -->
              <b-col md="6">
                <b-form-group
                  label="Type"
                  label-for="mc-type"
                >
                  <v-select
                    id="mc-type"
                    v-model="vendor.type"
                    :options="vendorTypes"
                    placeholder="Type"
                    class="w-100"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Email -->
              <b-col md="6">
                <b-form-group
                  label="Email"
                  label-for="mc-email"
                >
                  <b-form-input
                    id="mc-email"
                    v-model="vendor.email"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>

              <!-- Mailing Address -->
              <b-col md="6">
                <b-form-group
                  label="Mailing Address"
                  label-for="mc-maling_address"
                >
                  <b-form-input
                    id="mc-maling_address"
                    v-model="vendor.maling_address"
                    placeholder="Mailing Address"
                  />
                </b-form-group>
              </b-col>

              <!-- Phone -->
              <b-col md="6">
                <b-form-group
                  label="Phone"
                  label-for="mc-phone"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      US (+1)
                    </b-input-group-prepend>
                    <cleave
                      id="mc-phone"
                      v-model="vendor.phone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      placeholder="1234 567 8900"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Fax -->
              <b-col md="6">
                <b-form-group
                  label="Fax"
                  label-for="mc-fax"
                >
                  <b-form-input
                    id="mc-fax"
                    v-model="vendor.fax"
                    placeholder="Fax"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Address -->
              <b-col md="6">
                <b-form-group
                  label="Address"
                  label-for="mc-address"
                >
                  <b-form-input
                    id="mc-address"
                    v-model="vendor.address"
                    placeholder="Address"
                  />
                </b-form-group>
              </b-col>

              <!-- City -->
              <b-col md="6">
                <b-form-group
                  label="City"
                  :label-for="`mc-city`"
                >
                  <b-form-input
                    :id="`mc-city`"
                    v-model="vendor.city"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>

              <!-- State -->
              <b-col md="6">
                <b-form-group
                  label="State"
                  :label-for="`mc-state`"
                >
                  <v-select
                    :id="`mc-state`"
                    v-model="vendor.state"
                    :options="states"
                    label="abbreviated_state"
                    :reduce="state => state.abbreviated_state"
                    placeholder="State"
                    class="w-100"
                  >
                    <template
                      #option="{ abbreviated_state }"
                      :index="abbreviated_state"
                    >
                      {{ abbreviated_state }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- Zip -->
              <b-col md="6">
                <b-form-group
                  label="Zip"
                  :label-for="`mc-zip`"
                >
                  <b-form-input
                    :id="`mc-zip`"
                    v-model="vendor.zipcode"
                    placeholder="Zip"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- SCAC Code -->
              <b-col md="6">
                <b-form-group
                  label="SCAC Code"
                  label-for="mc-scac_code"
                >
                  <b-form-input
                    id="mc-scac_code"
                    v-model="vendor.scac_code"
                    placeholder="SCAC Code"
                  />
                </b-form-group>
              </b-col>

              <!-- DBA Name -->
              <b-col md="6">
                <b-form-group
                  label="DBA Name"
                  label-for="mc-dba_name"
                >
                  <b-form-input
                    id="mc-dba_name"
                    v-model="vendor.dba_name"
                    placeholder="DBA Name"
                  />
                </b-form-group>
              </b-col>

              <!-- US Dot Number -->
              <b-col md="6">
                <b-form-group
                  label="US Dot Number"
                  label-for="mc-usdot"
                >
                  <b-form-input
                    id="mc-usdot"
                    v-model="vendor.usdot"
                    placeholder="US Dot Number"
                  />
                </b-form-group>
              </b-col>

              <!-- MC/MX/FF -->
              <b-col md="6">
                <b-form-group
                  label="MC/MX/FF"
                  label-for="mc-operating_authority"
                >
                  <b-form-input
                    id="mc-operating_authority"
                    v-model="vendor.operating_authority"
                    placeholder="MC/MX/FF"
                  />
                </b-form-group>
              </b-col>

              <!-- Insurance Expiring Date -->
              <b-col md="6">
                <b-form-group
                  label="Insurance Expiring Date"
                  label-for="mc-insurance_expire_date"
                >
                  <cleave
                    id="mc-insurance_expire_date"
                    v-model="vendor.insurance_expire_date"
                    :options="options.date"
                    class="form-control"
                    :raw="false"
                    placeholder="Insurance Expiring Date"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Representative Name -->
              <b-col md="12">
                <div
                  ref="form"
                  class="repeater-form"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    @click="repeateAgain"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>Add</span>
                  </b-button>

                  <!-- Row Loop -->
                  <div
                    v-if="representives.length>0"
                    class="w-100"
                  >
                    <b-row
                      v-for="(item, index) in representives"
                      :id="index"
                      :key="index"
                      ref="row"
                    >

                      <!-- Full Name -->
                      <b-col md="4">
                        <b-form-group
                          label="Full Name"
                          label-for="full-name"
                        >
                          <b-form-input
                            id="full-name"
                            v-model="item.full_name"
                            type="text"
                            placeholder="Full Name"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Email Address -->
                      <b-col md="3">
                        <b-form-group
                          label="Email Address"
                          label-for="email_address"
                        >
                          <b-form-input
                            id="email_address"
                            v-model="item.email_address"
                            type="text"
                            placeholder="Email Address"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Contact Phone -->
                      <b-col md="2">
                        <b-form-group
                          label="Contact Phone"
                          label-for="contact_phone"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <cleave
                              id="contact_phone"
                              v-model="item.contact_phone"
                              class="form-control"
                              :raw="false"
                              :options="options.phone"
                              placeholder="1234 567 8900"
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <!-- Cell Phone -->
                      <b-col md="2">
                        <b-form-group
                          label="Cell Phone"
                          label-for="cell_phone"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <cleave
                              id="cell_phone"
                              v-model="item.cell_phone"
                              class="form-control"
                              :raw="false"
                              :options="options.phone"
                              placeholder="1234 567 8900"
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="1"
                        md="1"
                        class="mb-50 d-flex align-items-center"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          size="18"
                          class="text-danger"
                          style="cursor: pointer;"
                          @click="removeItem(index)"
                        />
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>

                </div>
                
              </b-col>

              <!-- submit and reset -->
              <b-col
                md="12"
                class="mt-1"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body>
      </b-card-code>
    </b-col>
    <b-col
      v-if="editId"
      md="12"
    >
      <file-upload-component
        :title="'Vendor Files'"
        :type="'Vendor'"
        :files="files"
      />
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCardBody, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { heightTransition } from '@core/mixins/ui/transition'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import vSelect from 'vue-select'
import FileUploadComponent from '@/components/FileUploadComponent.vue'
import * as vendorService from '@/services/vendor'
import states from '@/utils/state.json'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardBody,
    vSelect,
    Cleave,
    BInputGroup,
    BInputGroupPrepend,
    FileUploadComponent,
  },
  mixins: [heightTransition],
  data() {
    return {
      vendor: {
        name: '',
        type: '',
        legal_name: '',
        ssn: '',
        ein: '',
        email: '',
        phone: '',
        fax: '',
        address: '',
        maling_address: '',
        scac_code: '',
        dba_name: '',
        usdot: '',
        operating_authority: '',
        insurance_expire_date: '',
      },
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      vendorTypes: ['Carrier', 'Broker', 'Customer', 'Forwarder', 'Factoring Company'],
      editId: '',
      representives: [{
        full_name: '',
        email_address: '',
        contact_phone: '',
        cell_phone: '',
      }],
      files: [],
      states,
    }
  },
  created() {
    if (this.$route.name === 'edit-vendor') {
      this.editId = this.$route.params.id
      this.getvendorById(this.$route.params.id)
    }
  },
  methods: {
    // store vendor
    submit() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          if (this.$route.name === 'add-vendor') {
            this.create()
          } else {
            this.update()
          }
        }
      })
    },

    create() {
      vendorService.create({
        ...this.vendor,
        company_id: this.$store.state.auth.companyId,
        representives: this.representives,
      })
        .then(() => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: 'Vendor has been created successfully!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.$router.push({ name: 'vendor' })
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    update() {
      vendorService.update({
        ...this.vendor,
        id: this.editId,
        company_id: this.$store.state.auth.companyId,
        representives: this.representives,
      })
        .then(() => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: 'vendor has been updated successfully!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.$router.push({ name: 'vendor' })
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    // get vendor by id in edit
    getvendorById(id) {
      this.$store.commit('set_loading_action', true)
      vendorService.edit(id)
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.vendor = res.vendor
          this.representives = res.representives
          this.files = res.files
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          console.log(err)
        })
    },

    repeateAgain() {
      this.representives.push({
        full_name: '',
        email_address: '',
        contact_phone: '',
        cell_phone: '',
      })
    },

    removeItem(index) {
      this.representives.splice(index, 1)
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .previewImg {
    height: 150px;
    object-fit: contain;
    cursor: pointer;
  }
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
